require("iframe-resizer/js/iframeResizer.min");

// ODEventsEmbed("od-venue-ownership-1260-events-iframe");
(function () {

  function postMessageReceived(event, iframe){
    var payload = event.data;

    if(typeof(payload) === "object" && payload.from === "Opendate"){
      var command = payload.command;
      var data = payload.data;

      switch (command) {
        case "confirmLoaded":
          iframe.scrollIntoView();
          break;
        default:
          console.log("Unknown command \"" + command + "\" received from Opendate");
      }
    }
  }

  if(typeof ODEventsEmbed !== 'function'){
    window.ODEventsEmbed = function(iframeID, options){
      var iframe = document.getElementById(iframeID);
      var contentWindow = iframe.contentWindow;
      var options = (options || {});

      window['iFrameResize']({
        heightCalculationMethod: 'max',
        scrolling: false,
        onScroll: (iframe) => {
          return false;
        }
      }, `#${iframeID}`);

      var wrappedPostMessageReceived = function(event){
        postMessageReceived(
          event,
          iframe
        );
      }

      // https://robertnyman.com/html5/postMessage/postMessage.html
      if (window.addEventListener) {
        window.addEventListener("message", wrappedPostMessageReceived, false);
      } else {
        window.attachEvent("onmessage", wrappedPostMessageReceived);
      }
    };
  }
})();